<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Company</label>
            <b-select
              v-model="filter.company"
              :options="filterCompanies"
              @change="onTableRefresh"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            New Position
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        hover
        responsive
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              :disabled="state.busy"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              size="sm"
              :options="tableSettings.pageOptions"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-position-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="company"
            vid="company"
            rules="required"
          >
            <b-form-group>
              <label for="company">Company</label>
              <b-form-select
                id="company"
                v-model="position.company"
                :options="list.companies"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select company --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position code"
            vid="position_code"
            :rules="codeRule"
          >
            <b-form-group>
              <label for="position_code">Position Code</label>
              <b-input
                id="position_code"
                v-model="position.position_code"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter position code"
                autocomplete="off"
                maxlength="13"
                :disabled="state.editing"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="organization name"
            vid="position_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="position_name">Position Name</label>
              <b-input
                id="position_name"
                v-model="position.position_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter position name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="active"
              v-model="position.active"
              name="active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, UserPositionService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserPositions',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Positions'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        companies: []
      },
      filter: {
        company: 'All'
      },
      position: {
        id: 0,
        company: 0,
        position_code: '',
        position_name: '',
        active: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'position_code', sortable: true },
          { key: 'position_name', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {

    modalTitle () {
      return this.state.editing ? 'Edit Position' : 'New Position'
    },

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    },

    codeRule () {
      return this.state.editing ? '' : 'required|max:13|min:13'
    }
  },

  mounted () {
    core.index()
    this.fetchCompanyList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserPositionService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_company=${this.filter.company}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    },

    async fetchCompanyList () {
      await SharedListService.getCompanies().then(({ data }) => {
        this.list.companies = data.map(company => ({
          value: company.id,
          text: company.company_name
        }))
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.position.id = 0
      this.position.active = 1
      this.position.company = null
      this.position.position_name = ''
      this.position.position_code = ''
      this.$bvModal.show('modal-position-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.position.id = current.id
      this.position.active = current.active
      this.position.company = current.company_id
      this.position.position_name = current.position_name
      this.position.position_code = current.position_code
      this.$bvModal.show('modal-position-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create position?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserPositionService.post(this.position).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-position-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.onTableRefresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserPositionService.put(this.position).then(({ data }) => {
          this.$bvModal.hide('modal-position-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: this.position.id })
            row.active = data.position.active
            row.company = this.position.company
            row.position_name = this.position.position_name
            row.position_code = this.position.position_code
            row.updated_at = data.position.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
